@import "./colors.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Open+Sans&display=swap");

*,
*::after,
*::before {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: "Open Sans", sans-serif !important;
    /* font-family: 'Lato', sans-serif;
    font-family: 'Montserrat', sans-serif; */
}

.bg-blue-semi-dark {
    background-color: var(--blue-semi-dark);
}

.bg-blue-dark {
    background-color: var(--blue-dark);
}

.bg-blue-light {
    background-color: var(--blue-light);
}

.bg-dark-blue-newsletter {
    background-color: var(--blue-newsletter);
}

.text-blue-semi-dark {
    color: var(--blue-semi-dark);
}

.text-blue-dark {
    color: var(--blue-dark);
}

.text-blue-light {
    color: var(--blue-light);
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.601);
}

.as-btn {
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    -webkit-box-shadow: 0px 6px 16px rgb(13 94 244 / 24%);
    box-shadow: 0px 6px 16px rgb(13 94 244 / 24%);
}

.animated-btn a {
    box-shadow: none;
    text-decoration: none;
}

.animated-btn a:hover {
    text-decoration: none;
    outline: 0;
}

.animated-btn a:hover::before {
    height: 500px;
}

.as-btn::before {
    content: "";
    position: absolute;
    height: 0%;
    left: 50%;
    top: 50%;
    width: 600%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(60deg);
    transform: translateX(-50%) translateY(-50%) rotate(60deg);
    z-index: -1;
    -webkit-transition: 0.7s ease;
    transition: 0.7s ease;
}

.as-btn.extra-dark-blue-bg::before {
    background-color: var(--blue-newsletter);
}

.as-btn.semi-dark-blue-bg::before {
    background-color: var(--blue-semi-dark);
}

.as-btn.white-bg:hover {
    outline: 4px solid #ffffff;
    outline-offset: -4px;
}

.as-btn.white-bg::before {
    line-height: 0;
    background-color: #ffffff;
}

ul.event-tabs li {
    @apply text-2xl font-bold underline underline-offset-8 uppercase cursor-pointer;
}

.custom-table thead tr th {
    @apply border border-gray-300 py-1 px-2  bg-blue-semi-dark text-white;
}

.custom-table tbody tr td {
    @apply border border-gray-300 py-1 px-2 text-center;
}

.ck-editor__editable_inline {
    min-height: 150px;
}

@media (max-width: 1024px) {
    ul.event-tabs li {
        @apply text-sm font-bold underline underline-offset-8 uppercase cursor-pointer;
    }
}

@media (max-width: 576px) {
    ul.event-tabs li {
        @apply text-xs;
    }
}
