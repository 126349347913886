.profile-steps.active .step-no {
    background-color: var(--blue-light);
}

.profile-steps .step-no {
    @apply px-4 py-2 rounded-full mr-3 font-bold text-xl w-fit relative;
}

.profile-steps.inactive .step-no {
    @apply bg-gray-600 text-white;
}

.profile-steps.complete .step-no {
    @apply bg-green-600 text-white;
}

.profile-steps .step-name {
    @apply self-center text-base md:text-xl;
}

.profile-steps {
    @apply mb-28 pl-0 md:pl-[30%] items-center md:items-start space-y-2 md:space-y-0 relative;
}

@media (min-width: 578px) {
    .profile-steps .step-one::after,
    .profile-steps .step-two::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -8rem;
        height: 127px;
        border-left: 2px dotted #ffffff;
    }

    .profile-steps.complete .step-one::after,
    .profile-steps.complete .step-two::after {
        height: 128px;
        border-left: 2px solid #0cbd33;
    }
}

@media (max-width: 576px) {
    .profile-steps .step-one::after,
    .profile-steps .step-two::after {
        content: "";
        position: absolute;
        right: -90%;
        top: -38%;
        height: 77px;
        transform: rotate(90deg);
        border-left: 2px dotted #ffffff;
    }

    .profile-steps.complete .step-one::after,
    .profile-steps.complete .step-two::after {
        border-left: 2px solid #0cbd33;
    }
}
