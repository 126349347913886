.ef-team-wrapper-header h1,
h2.eft-student-name {
    background: rgb(126, 255, 211);
    background: linear-gradient(
        135deg,
        rgba(126, 255, 211, 1) 0%,
        rgba(70, 223, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ef-team-wrapper-header div.gradient-underline,
.ef-dt-bg {
    background: rgb(126, 255, 211);
    background: linear-gradient(
        135deg,
        rgba(126, 255, 211, 1) 0%,
        rgba(70, 223, 255, 1) 100%
    );
}

.dt-item-popup {
    visibility: hidden;
    background: rgb(95, 231, 255);
    background: linear-gradient(
        135deg,
        rgba(95, 231, 255, 1) 0%,
        rgba(221, 250, 255, 1) 100%
    );

    position: relative;
    margin-top: -10rem;
}

.dt-team-item:hover .dt-item-popup {
    visibility: visible;
}

.eft-stdteam-bg-text {
    font-size: 20rem;
    bottom: -5rem;
    right: -5rem;
}

.development-team .slick-arrow,
.main-team-carousel .slick-arrow {
    top: auto;
    transform: none;
}

.development-team .prev-arrow,
.main-team-carousel .prev-arrow {
    left: auto;
    top: -7rem;
    right: 8rem;
}

.development-team .next-arrow,
.main-team-carousel .next-arrow {
    top: -7rem;
    right: 4rem;
}

.development-team .prev-arrow img,
.development-team .next-arrow img {
    @apply w-8 md:w-10 lg:w-12;
}

.student-team-carousel .prev-arrow {
    left: -2rem;
}

.student-team-carousel .next-arrow {
    right: -2rem;
}

@media (max-width: 576px) {
    .development-team .prev-arrow {
        left: auto;
        top: -4.5rem;
        right: 5.5rem;
    }

    .development-team .next-arrow {
        top: -4.5rem;
        right: 3rem;
    }

    .eft-stdteam-bg-text {
        font-size: 10rem;
        bottom: -2rem;
        right: -2rem;
    }
}
