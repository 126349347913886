.anp-header .ph-img-wrapper {
    height: 90vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.anp-header .anp-slider-caption {
    width: 100%;
    background-color: rgba(17, 61, 144, 0.9);
}

.news-highlights .right-arrow-btn {
    background-color: var(--blue-light);
    margin-left: 1rem;
}

.news-highlights h3 {
    text-underline-offset: 0.8rem;
    text-decoration-color: var(--blue-light);
}

.anp-page-name {
    border-left-color: var(--blue-light);
}

.anp-split-bg-blue-colors {
    background: linear-gradient(
        0deg,
        var(--blue-light) 65%,
        var(--blue-dark) 65%
    );
}

.yap-next-text {
    text-decoration-color: #ff9f5b;
}

.yap-next-info {
    border-left-color: #ff9f5b;
}

.nd-split-bg-color {
    background: linear-gradient(0deg, #c6f9f3 20%, var(--blue-dark) 20%);
}

@media (max-width: 820px) {
    .anp-header .ph-img-wrapper {
        height: 50vh;
    }
    .news-highlights h3 {
        text-underline-offset: 0.5rem;
    }
}

@media (max-width: 576px) {
    .anp-header .ph-img-wrapper {
        height: 50vh;
    }
}
