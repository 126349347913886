.facilities-about h1 {
    background: rgb(126, 255, 211);
    background: linear-gradient(
        135deg,
        rgba(126, 255, 211, 1) 0%,
        rgba(70, 223, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.facilitites-carousel-item .circle-img {
    background-position: center center;
    background-size: cover;
    height: 325px;
    width: 325px;
}

.slick-active.slick-current .facilitites-carousel-item .circle-img {
    border: 5px solid #38b7ff;
}

.facilties-info-wrapper::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #ffffff;
    position: absolute;
    top: -1.8rem;
    left: 48%;
    transform: translateX(-48%);
    transform: rotate(180deg);
}

.facility-info-item {
    display: none;
}

.facility-info-item.active {
    display: block;
}

.facilities-carousel .slick-arrow {
    top: 42%;
}

.facilities-carousel .prev-arrow {
    left: -1rem;
}

.facilities-carousel .next-arrow {
    right: -1rem;
}

.facility-images-carousel .next-arrow {
    right: 0;
}

@media (max-width: 1280px) {
    .facilitites-carousel-item .circle-img {
        height: 260px;
        width: 260px;
    }
}

@media (max-width: 1024px) {
    .facilitites-carousel-item .circle-img {
        height: 180px;
        width: 180px;
    }
}

@media (max-width: 820px) {
    .facilties-info-wrapper::before {
        left: 46%;
        transform: translateX(-46%);
        transform: rotate(180deg);
    }
}

@media (max-width: 576px) {
    .facilitites-carousel-item .circle-img {
        height: 200px;
        width: 200px;
    }
    .facilties-info-wrapper::before {
        left: 42%;
        transform: translateX(-42%);
        transform: rotate(180deg);
    }
}
