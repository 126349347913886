.text-input {
    @apply border-b-2 md:border-b-2 border-b-cyan-400 py-2 bg-transparent focus:outline-none w-full;
}

.input-error {
    @apply text-red-500 text-base mt-1;
}

.checkbox-input input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
}

.checkbox-input input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #ff0000;
}

.checkbox-input input[type="checkbox"]:checked::before {
    transform: scale(1);
}
