@media screen and (max-width: 600px) {
    .pushNav {
        width: 75%;
        right: -75%;
    }
}
@media screen and (min-width: 601px) {
    .pushNav {
        width: 350px;
        right: -350px;
    }
}
ul.pushNav {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.pushNav {
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f2f2f5;
    transition: ease-in-out 0.5s;
}
.pushNav hr {
    border: 1px solid var(--blue-dark);
}

.pushNav,
.pushNav a {
    font-size: 1em;
    font-family: helvetica, sens-serif;
    font-weight: 100;
    color: rgb(7, 7, 7);
    text-decoration: none;
}

.pushNavIsOpen {
    overflow: auto;
    height: 100%;
}

.js-topPushNav.isOpen,
.pushNav_level.isOpen {
    right: 0;
}

.closeLevel,
.openLevel {
    cursor: pointer;
}

.openLevel,
.closeLevel,
.pushNav a {
    padding: 1em 0;
    display: block;
    text-indent: 20px;
    transition: background 0.25s ease-in-out;
}
.openLevel:hover,
.closeLevel:hover,
.pushNav a:hover {
    background: #bababa;
}

.hdg {
    background-color: var(--blue-dark);
    text-indent: 0;
    color: #f2f2f5;
}

.screen {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    z-index: 1;
    height: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}

.pushNavIsOpen .screen {
    height: 100%;
    opacity: 1;
}

.menu-link-w-icon {
    display: flex;
}

.menu-link-w-icon i {
    @apply text-xl mt-[0.1rem];
}

.menu-link-w-icon i.left {
    @apply ml-4;
}
