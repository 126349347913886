.slick-arrow {
    z-index: 99;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

/* .prev-arrow {
    left: -2.5rem;
}

.next-arrow {
    right: -2.5rem;
} */
