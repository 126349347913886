.alumni-database-wrapper {
    background: linear-gradient(0deg, var(--blue-dark) 50%, #0c25ae 50%);
}

.custom-select-input {
    padding: 0.5rem 1rem;
    width: 20%;
    font-size: 1.3rem;
}

.year-carousel-underline {
    background: rgb(0, 198, 255);
    background: linear-gradient(
        93deg,
        rgba(0, 198, 255, 1) 0%,
        rgba(26, 155, 255, 1) 100%
    );
    height: 0.5rem;
    width: 100%;
    margin-top: 1rem;
}

.multi-year-text {
    background: rgb(26, 155, 255);
    background: linear-gradient(
        90deg,
        rgba(26, 155, 255, 1) 0%,
        rgba(0, 198, 255, 1) 20%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.year-carousel-wrapper .slick-prev {
    position: absolute;
    left: 0;
    z-index: 9999;
}

.year-carousel-wrapper .slick-next {
    position: absolute;
    right: 0;
    z-index: 9999;
}

.multi-year-carousel .slick-list {
    @apply py-10;
}

button.year-carousel-btn.active {
    @apply underline underline-offset-8 decoration-blue-400 decoration-4;
}

/* .alumni-db-year-carousel .slick-prev {
    left: -1rem;
} */
