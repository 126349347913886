.wd-page-header-text h1 {
    background: rgb(181, 245, 140);
    background: linear-gradient(
        90deg,
        rgba(181, 245, 140, 1) 0%,
        rgba(68, 223, 222, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ef-stats .text-gradient h1 span {
    color: rgb(181, 245, 140);
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.wd-your-donation-bg,
.wd-endowment-point {
    background: rgb(232, 255, 217);
    background: linear-gradient(
        135deg,
        rgba(232, 255, 217, 1) 11%,
        rgba(173, 245, 247, 1) 100%
    );
}

.wd-page-header-underline {
    background: rgb(134, 253, 255);
    background: linear-gradient(
        135deg,
        rgba(134, 253, 255, 1) 0%,
        rgba(189, 255, 202, 1) 49%,
        rgba(211, 255, 182, 1) 100%
    );
}

.wd-your-donation-carousel .slick-arrow {
    position: absolute;
    top: -6rem;
}

.wd-your-donation-carousel .slick-arrow.prev-arrow {
    right: 7.5rem;
}

.wd-your-donation-carousel .slick-arrow.next-arrow {
    right: 4rem;
}

@media (max-width: 1280px) {
    .wd-your-donation-carousel .slick-arrow.prev-arrow {
        right: 7.5rem;
    }
}

@media (max-width: 820px) {
    .wd-your-donation-carousel .slick-arrow.prev-arrow {
        right: 7.5rem !important;
    }
}

@media (max-width: 576px) {
    .wd-your-donation-carousel .slick-arrow.next-arrow {
        right: 2rem;
    }
    .wd-your-donation-carousel .slick-arrow.prev-arrow {
        right: 5rem !important;
    }
}
