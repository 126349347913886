.scholarships-bg-text {
    font-size: 12rem;
}

.album-item,
.album-image-item {
    background-size: cover;
    background-position: center;
}

@media (max-width: 576px) {
    .scholarships-bg-text {
        font-size: 8rem;
    }
}
