.recognition-about-data h3 {
    @apply text-white uppercase underline underline-offset-[1rem] decoration-cyan-300 text-sm md:text-3xl;
}

.recognition-about-data .gradient-text {
    background: rgb(111, 186, 254);
    background: linear-gradient(
        90deg,
        rgba(111, 186, 254, 1) 0%,
        rgba(183, 252, 149, 1) 81%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.recognition-about-data .gradient-text {
    @apply text-base md:text-4xl lg:text-6xl font-bold mt-8;
}

.recognition-bg-text {
    color: rgba(255, 255, 255, 0.05);
    font-size: 14rem;
}

.recognition-members-table-bg {
    background: rgb(203, 246, 255);
    background: linear-gradient(
        90deg,
        rgba(203, 246, 255, 1) 0%,
        rgba(255, 255, 255, 1) 70%
    );
}

table.member-info-modal {
    margin-top: 1rem;
}

table.member-info-modal tr td:last-child {
    padding-left: 1rem;
}

table.member-info-modal tr td:first-child {
    font-weight: bold;
}

table.recognition-members-table {
    width: 100%;
    height: 100%;
}

.slick-vertical .slick-slide {
    border: none;
}

.recognition-board-members
    .slick-slide.slick-active.slick-center.slick-current
    .member-img {
    border: 5px solid #71d2ff;
    width: 85%;
}

.recognition-carousel .slick-arrow {
    top: auto;
    transform: none;
}

.recognition-carousel .prev-arrow {
    top: -2rem;
}

.recognition-carousel .prev-arrow {
    @apply left-[45%] md:left-[6%] lg:left-[8%];
}

.recognition-carousel .prev-arrow img,
.recognition-carousel .next-arrow img {
    @apply shadow-xl rounded-full;
}

.recognition-carousel .next-arrow {
    @apply left-[45%] md:left-[6%] lg:left-[8%];
}

.recognition-carousel .next-arrow {
    bottom: -2rem;
}

/* .members-wrapper:first-child .member-img-wrapper,
.members-wrapper:last-child .member-img-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
} */

.recognition-board-members .slick-track {
    height: 100% !important;
}

.recognition-board-members
    .slick-slide.slick-active.slick-center.slick-current
    .recognition-members-table.member-info {
    @apply font-bold;
}

.recognition-board-members
    .slick-slide.slick-active.slick-center.slick-current
    .member-name {
    @apply font-bold text-lg lg:text-xl;
}

.recognition-members-table-bg.member-info {
    @apply text-xl;
}

table.recognition-members-table thead tr th {
    @apply px-2 py-6 uppercase font-bold text-lg border-b-2 border-b-cyan-300;
}

table.recognition-members-table tbody tr td {
    @apply px-2 py-6 uppercase text-lg text-center;
}

.recognition-about-data h3 {
    @apply text-xs md:text-base;
}

.member-info-modal h3 {
    @apply font-bold text-base md:text-lg;
}

.member-info-modal .member-info-text {
    @apply text-sm;
}

.recognition-board-members {
    background: rgb(177, 204, 250);
    background: linear-gradient(
        162deg,
        rgba(177, 204, 250, 1) 35%,
        rgba(183, 236, 253, 1) 99%
    );
}

@media (max-width: 820px) {
    .recognition-bg-text {
        font-size: 8rem;
    }
}

@media (max-width: 576px) {
    .recognition-bg-text {
        font-size: 4rem;
    }
}
