ul.forum-ul-categories li.active {
    @apply border-l-[5px] border-l-blue-900 bg-blue-200 pl-4 font-bold;
}

ul.forum-ul-categories li {
    @apply px-2 py-1 mb-2 border border-transparent md:text-sm lg:text-base;
}

ul.forum-ul-categories li:hover {
    @apply bg-blue-50 border border-blue-100;
}

ul.forum-ul-categories li a {
    display: block;
}

ul.forum-ul-categories li.active:hover {
    @apply border-l-[5px] border-l-blue-900 border-blue-200;
}
