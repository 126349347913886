
.bg-text{
    position: absolute;
    line-height: 1;
    font-weight: 600;
    color: rgba(255,255,255,.12);
    font-size: 15rem;
    z-index: 0;
}

.light-blue-dot{
    background-color: var(--blue-lightest);
}

.read-more-btn{
    border-bottom: 4px solid var(--blue-light);
}

.split-winners-bg-colors{
    background: linear-gradient(0deg, var(--blue-dark) 11%, var(--blue-lightest) 11%);
}

.award-winner-item .read-more-btn{
    border-left-color: var(--blue-light);
    border-bottom: none;
}

.pagination-buttons button{
    margin-right: 1rem;
}

.pagination-buttons button{
    @apply text-white text-3xl;
}

.pagination-buttons button.active{
    border: 2px solid var(--blue-light);
    @apply text-5xl p-1;
}

.pagination-buttons button:last-child{
    margin-right: 3rem;
}

.pagination-buttons{
    border-bottom: 6px solid var(--blue-light);
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media(max-width: 820px){
    .bg-text{
        font-size: 12rem;
    }

}

@media(max-width: 576px){
    .bg-text{
        font-size: 8rem;
    }

    .about-bg-text{
        font-size: 6rem;
    }

    .split-winners-bg-colors{
        background: linear-gradient(0deg, var(--blue-dark) 3%, var(--blue-lightest) 3%);
    }
}