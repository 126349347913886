.class-contribution-header {
    background: rgb(100, 184, 255);
    background: linear-gradient(
        93deg,
        rgba(100, 184, 255, 1) 0%,
        rgba(109, 255, 223, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.year-carousel-wrapper .slick-list {
    overflow: inherit;
}

.contribution-year-carousel-underline {
    background: rgb(26, 155, 255);
    background: linear-gradient(
        93deg,
        rgba(26, 155, 255, 1) 0%,
        rgba(0, 198, 255, 1) 100%
    );
    height: 1rem;
    width: 100%;
    border-radius: 0.5rem;
}
