.single-page-header {
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 820px) {
    .single-page-header {
        height: 38vh;
    }
}

@media (max-width: 576px) {
    .single-page-header {
        height: 25vh;
    }

    .single-page-header div {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}
