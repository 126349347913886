:root {
    --facebook-btn-bg: #365899;
    --google-btn-bg: #e53935;
    --linkedin-btn-bg: #039be5;
    --blue-dark: #01286f;
    --blue-semi-dark: #1e4a99;
    --blue-light: #08bff7;
    --blue-lighter: #5c88d6;
    --blue-lightest: #85d2e5;
    --blue-very-light: #D3E6FD;
    --blue-contact-1: #21a9fd;
    --blue-contact-2: #4171FB;
    --blue-footer:#002267;
    --blue-newsletter: #001642;
    --yap-dark-color: #1C2F4B;
}