.modal-overlay {
    background-color: rgb(0, 0, 0, 0.75);
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

.modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.modal-body {
    padding: 2.5rem 2rem 2rem 2rem;
    background-color: #ffffff;
    min-width: 30vw;
    max-width: 95vw;
}

.modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ff0000;
}

@media (max-width: 576px) {
    .modal-body {
        min-width: 95vw;
        padding: 2rem 1rem 1rem 1rem;
    }
}
