.aa-bg-president-message {
    background: rgb(183, 236, 254);
    background: linear-gradient(
        270deg,
        rgba(183, 236, 254, 1) 16%,
        rgba(177, 204, 250, 1) 100%
    );
}

.aa-board-bg-gradient {
    background: rgb(230, 247, 255);
    background: linear-gradient(
        270deg,
        rgba(230, 247, 255, 1) 11%,
        rgba(119, 212, 255, 1) 100%
    );
}

.aa-img-wrapper {
    background: linear-gradient(90deg, #00b7ff 16%, #00dcff 100%) padding-box;
    border-radius: 500px;
    border: 4px solid transparent;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;
    cursor: pointer;
    transition: color 0.2s;
}

.aa-message-underline {
    background: linear-gradient(90deg, #00b7ff 16%, #00dcff 100%);
    height: 4px;
    margin-top: 0.5rem;
    width: 50%;
}

.aa-img-wrapper img {
    border-radius: inherit;
}

.ec-members-carousel .prev-arrow img,
.ec-members-carousel .next-arrow img {
    @apply w-10;
}

@media (max-width: 576px) {
    .ec-members-carousel .prev-arrow {
        left: 2.5rem;
    }

    .ec-members-carousel .next-arrow {
        right: 2.5rem;
    }

    .aa-bg-text {
        font-size: 7rem;
        left: 0.5rem;
    }
}
