.yap-about {
    background: rgb(98, 98, 98);
    background: linear-gradient(
        90deg,
        rgba(98, 98, 98, 1) 0%,
        rgba(156, 156, 156, 1) 100%
    );
}

.yap-about-border {
    background: rgb(214, 126, 60);
    background: linear-gradient(
        90deg,
        rgba(214, 126, 60, 1) 0%,
        rgba(156, 156, 156, 1) 55%
    );
    height: 0.5rem;
}

.yap-bio {
    background: rgb(255, 157, 84);
    background: linear-gradient(
        90deg,
        rgba(255, 157, 84, 1) 0%,
        rgba(255, 147, 117, 1) 100%
    );
}

.yap-bio-head {
    font-size: 15rem;
    margin-top: -4rem;
}

.yap-gradient-border {
    background: rgb(214, 126, 60);
    background: linear-gradient(
        180deg,
        rgba(214, 126, 60, 1) 0%,
        rgba(221, 221, 221, 1) 100%
    );
    width: 5px;
    height: 14rem;
    margin-top: 5rem;
    margin-left: 2rem;
}

.yap-next-alumni {
    background-color: var(--yap-dark-color);
}

.yap-share-text {
    writing-mode: vertical-lr;
    text-orientation: upright;
    color: #ff9f5b;
}

.yap-social-btns a img {
    width: 70px;
    margin-right: 0.5rem;
}

.yap-social-btns a:last-child img {
    margin-right: 0;
}

.yap-bio-body p {
    margin-bottom: 1rem;
}

.yap-na-img-wrapper {
    background: linear-gradient(180deg, #ff9f5b 50%, var(--yap-dark-color) 50%)
        padding-box;
    border-radius: 500px;
    border: 4px solid transparent;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;
    cursor: pointer;
    transition: color 0.2s;
}

.yap-na-img-wrapper img {
    border-radius: inherit;
}

@media (max-width: 1280px) {
    .yap-bio-head {
        font-size: 12rem;
    }
    .yap-gradient-border {
        height: 10rem;
        margin-top: 5rem;
    }
}

@media (max-width: 820px) {
    .yap-bio-head {
        font-size: 10rem;
        margin-top: -1.5rem;
    }
    .yap-gradient-border {
        height: 9rem;
        margin-top: 3.5rem;
    }
}

@media (max-width: 576px) {
    .yap-bio-head {
        font-size: 5rem;
        margin-top: -1.5rem;
    }
    .yap-gradient-border {
        height: 5rem;
        margin-top: 1.5rem;
    }
}
