.register-gradient-text {
    background: rgb(100, 184, 255);
    background: linear-gradient(
        93deg,
        rgba(100, 184, 255, 1) 0%,
        rgba(109, 255, 223, 1) 20%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.login-btn {
    background: rgb(100, 184, 255);
    background: linear-gradient(
        93deg,
        rgba(100, 184, 255, 1) 0%,
        rgba(109, 255, 223, 1) 80%
    );
}

.register-from-wrapper {
    background-position: center center;
    background-size: cover;
}

.background-overlay {
    background-color: rgba(0, 22, 66, 0.9);
}

.background-overlay .container {
    background-image: url("../girl.png");
    background-position: 95%;
    background-repeat: no-repeat;
    background-size: contain;
}

.register-social-links {
    background: rgb(1, 71, 143);
    background: linear-gradient(
        93deg,
        rgba(1, 71, 143, 1) 0%,
        rgba(11, 134, 152, 1) 70%
    );
}

.register-extra-links a {
    @apply border-r-2 border-r-white pr-4;
}

.register-extra-links a:last-child {
    @apply border-r-0;
}

.register-social-buttons a {
    @apply text-white mb-4 px-4 py-2 text-center;
}

.register-social-buttons a:last-child {
    @apply mb-0;
}

@media (max-width: 576px) {
    .background-overlay .container {
        background-image: none;
    }
}
