.aaf-header .text-gradient,
.introduction-title {
    background: rgb(29, 215, 255);
    background: linear-gradient(
        90deg,
        rgba(29, 215, 255, 1) 0%,
        rgba(60, 255, 202, 1) 25%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.aaf-split-bg {
    background: linear-gradient(0deg, var(--blue-dark) 50%, #173e85 50%);
}

.aaf-tem-bg-gradient {
    background: rgb(56, 164, 255);
    background: linear-gradient(
        90deg,
        rgba(56, 164, 255, 1) 0%,
        rgba(59, 231, 216, 1) 89%
    );
}

.aaf-top-stories-bg {
    background: rgb(29, 215, 255);
    background: linear-gradient(
        90deg,
        rgba(29, 215, 255, 1) 0%,
        rgba(60, 255, 202, 1) 80%
    );
}

.aaf-team-carousel .next-arrow {
    right: -0.7rem;
}

.aaf-team-carousel .prev-arrow {
    left: -0.7rem;
}
