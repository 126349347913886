.bg-orange-gradient{
    background: rgb(190,84,7);
    background: linear-gradient(90deg, rgba(190,84,7,1) 0%, rgba(231,146,78,1) 100%);
}

.young-alumni-winners{
    background: rgb(255,157,84);
    background: linear-gradient(90deg, rgba(255,157,84,1) 0%, rgba(255,147,117,1) 100%);
}

.bg-dark{
    background-color: #3E3E3E;
}

.young-read-more-btn{
    background: rgb(207,176,106);
    background: linear-gradient(90deg, rgba(207,176,106,1) 0%, rgba(170,191,122,1) 100%);
}