.htd-page-heading {
    background: rgb(181, 245, 140);
    background: linear-gradient(
        90deg,
        rgba(181, 245, 140, 1) 0%,
        rgba(68, 223, 222, 1) 20%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.htd-gradient-underline {
    background: rgb(181, 245, 140);
    background: linear-gradient(
        90deg,
        rgba(181, 245, 140, 1) 0%,
        rgba(68, 223, 222, 1) 80%
    );
}

.htd-transfer-info-bg {
    background: rgb(121, 192, 250);
    background: linear-gradient(
        90deg,
        rgba(121, 192, 250, 1) 0%,
        rgba(190, 235, 219, 1) 81%
    );
}

.htd-white-blue-gradient {
    background: rgb(251, 253, 255);
    background: linear-gradient(
        160deg,
        rgba(251, 253, 255, 1) 0%,
        rgba(169, 205, 248, 1) 62%
    );
}

.htd-transfer-methods-tabs .tab-item.active::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #93d8d1;
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    right: -1.9rem;
    transform: rotate(270deg);
}

.give-donors-list .slick-dots li button:before {
    color: var(--blue-light);
}

.give-donors-list .slick-dots li.slick-active button:before {
    color: var(--blue-light);
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1.5rem;
    margin-right: 0.5rem;
    height: 5px;
    background-color: var(--blue-light);
    border-radius: 5px;
}

.htd-transfer-methods-tabs .tab-item {
    @apply bg-white p-4 md:p-8 rounded-xl w-[97%] lg:w-1/2 mb-4 text-xl md:text-2xl font-bold text-center cursor-pointer relative;
}

.htd-transfer-methods-tabs .tab-item.active {
    @apply border-4 border-blue-400;
}

.payment-methods-info h4 {
    @apply mb-3 text-xl;
}

.payment-methods-info h4.labels {
    @apply font-bold;
}

.tab-info.active {
    display: block;
}

.tab-info {
    display: none;
}

.transfer-info-table tbody tr td {
    @apply p-2 w-1/2 text-sm md:text-lg border-r-2 border-r-white md:pl-4 lg:pl-12;
}

.transfer-info-table tbody tr td:last-child {
    @apply border-r-0;
}

.transfer-info-table tbody tr:nth-child(odd) td:first-child {
    @apply font-bold;
}
