.bg-blue-footer {
    background: var(--blue-footer);
}

.footer-social-icons a {
    margin-right: 1.5rem;
    font-size: 2.5rem;
}

.footer-social-icons a:last-child {
    margin-right: 0;
}

.footer-social-icons a:hover {
    color: #ffffff;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-links li {
    margin-bottom: 1rem;
}

.footer-links-wrapper {
    border-right: 2px solid var(--blue-semi-dark);
}

summary.footer-expand-btn {
    border-bottom: 2px solid var(--blue-semi-dark);
}

.footer-links-wrapper:last-child {
    border-right: none;
}

.expand-footer summary {
    position: relative;
}

details.expand-footer summary::marker {
    display: none;
    opacity: 0 !important;
    position: absolute;
    right: 0;
}

@media (max-width: 576px) {
    .footer-links-wrapper {
        border-right: none;
    }
    .justify-evenly.footer-links a {
        font-size: 0.9rem;
        text-align: center;
    }
}
