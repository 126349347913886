.ttd-about-blue-text {
    background: rgb(0, 222, 255);
    background: linear-gradient(
        135deg,
        rgba(0, 222, 255, 1) 0%,
        rgba(0, 180, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ttd-bg-text {
    font-size: 12rem;
    margin-top: -3rem;
}

.ttd-blue-underline {
    text-decoration-color: var(--blue-light);
}

.ttd-abstract-body p {
    margin-bottom: 0.8rem;
}

.ttd-split-bg-colors {
    background: linear-gradient(0deg, var(--blue-dark) 41%, #ffffff 41%);
}

@media (max-width: 1280px) {
    .ttd-split-bg-colors {
        background: linear-gradient(0deg, var(--blue-dark) 38%, #ffffff 38%);
    }
}

@media (max-width: 1024px) {
    .ttd-split-bg-colors {
        background: linear-gradient(0deg, var(--blue-dark) 30%, #ffffff 30%);
    }
}

@media (max-width: 576px) {
    .ttd-bg-text {
        font-size: 8rem;
        margin-top: 50%;
        transform: translateY(-50%);
    }

    .ttd-split-bg-colors {
        background: linear-gradient(0deg, var(--blue-dark) 15%, #ffffff 15%);
    }
}
