@media (max-width: 1024px) {
    .newsletter-bg-text {
        font-size: 10rem;
    }
}

@media (max-width: 1024px) {
    .newsletter-bg-text {
        font-size: 4.5rem;
    }
}
