.navbar-menu-items li.menu-item {
    margin-right: 2.2rem;
    position: relative;
    border-bottom: 4px solid var(--blue-semi-dark);
}

.navbar-menu-items li:last-child {
    margin-right: 0;
}

.navbar-menu-items li.dropdown a {
    cursor: pointer;
}

.navbar-menu-items li.dropdown::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid var(--blue-light);
    position: absolute;
    right: -0.85rem;
    top: 0.7rem;
    transition: 500ms all;
}

.navbar-menu-items li.dropdown:hover::before {
    transform: rotate(180deg);
}

.border-light-blue {
    border-color: var(--blue-light);
}

button.rgu-network-btn {
    position: absolute;
    right: 1rem;
    top: -1.3rem;
    font-size: 1.2rem;
    z-index: 2;
}

.rgu-network-buttons {
    display: flex;
}

.rgu-network-buttons a,
.rgu-network-buttons button {
    margin-right: 1.5rem;
    color: #ffffff;
    padding: 0.4rem 0.8rem;
}

.facebook-connect-btn {
    background-color: var(--facebook-btn-bg);
}

.google-connect-btn {
    background-color: var(--google-btn-bg);
}

.linkedin-connect-btn {
    background-color: var(--linkedin-btn-bg);
}

.dropdown:hover .dropdown-content {
    visibility: visible;
}

/* .dropdown-content{
    opacity: 0;
    position: absolute;
    min-width: 27.5em;
    height: auto;
    padding: 1rem;
    background-color: #FFFFFF;
    color: black;
    top: 3rem;
    left: calc(50% - 20.5em);
    z-index: 99;
    border-radius: 1rem;
    transition: all 0.25s ease-out, opacity 0.25s ease-in, margin-left 0.25s ease-out;
} */

.dropdown-content {
    background-color: #ffffff;
    position: absolute;
    color: #000000;
    border-radius: 10px;
    min-width: 47em;
    /* width: fit-content; */
    z-index: 99;
    clip-path: inset(0 15em 15em 15em);
    visibility: hidden;
    left: calc(50% - 42.5em);
    font-weight: initial;
    padding: 1.5em;
    top: 3rem;
    transition-delay: 200ms;
    transition: all 0.25s ease-out, opacity 0.2s ease-in,
        margin-left 0.25s ease-out, clip-path 0.15s ease-out;
    border: 1px solid #dddddd;
}

.dropdown-compact .dropdown-content {
    min-width: 35em;
    left: calc(50% - 30em);
}

.navbar-menu-items li.menu-item.dropdown:hover .dropdown-content {
    pointer-events: all;
    clip-path: inset(-0.8rem 0 0 0);
}

.navbar-menu-items li.menu-item.dropdown:hover .dropdown-content::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
    position: absolute;
    top: -10px;
    overflow: visible;
    z-index: inherit;
    right: 4rem;
    transform: rotate(180deg);
}

.navbar-menu-items li.menu-item.dropdown:hover {
    border-bottom: 4px solid var(--blue-light);
    border-spacing: 8px;
}

ul.dropdown-content-links li {
    font-size: 1.05rem;
}

.dropdown-content-links li a {
    @apply flex leading-6 font-semibold mb-3 hover:underline;
}

.dropdown-content-links li a span {
    @apply mt-[0.3rem] mr-1;
}

.dropdown-content-image .img-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 5%,
        rgba(92, 136, 214, 0) 23%
    );
    z-index: 9;
    bottom: -1.5rem;
    right: -1.5rem;
}

.dropdown-content-image img {
    -webkit-mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(255, 255, 255, 1))
    );
    mask-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 75%
    );
}

/* .profile-dropdown {
    display: none;
}

.userinfo:hover + .profile-dropdown {
    display: block !important;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9;
    left: -150%;
} */

.dropdown-login .dropdown-content {
    min-width: 160px;
    left: calc(50% - 120px);
}

.profile-dropdown a,
.profile-dropdown button {
    color: #000000;
}

.navbar-menu-items li.menu-item.dropdown-login:hover .dropdown-content::before {
    right: 1rem !important;
}

@media (max-width: 1280px) {
    .dropdown-content {
        left: calc(50% - 32.5em);
        min-width: 40em;
    }
    .navbar-menu-items li.menu-item.dropdown:hover .dropdown-content::before {
        right: 7rem;
    }

    .navbar-menu-items
        li.menu-item.dropdown:hover
        .dropdown-login
        .dropdown-content::before {
        right: 1rem !important;
    }

    .dropdown-compact .dropdown-content {
        min-width: 35em;
        left: calc(50% - 28em);
    }
}

@media (max-width: 576px) {
    button.rgu-network-btn {
        font-size: 1rem;
    }
    .rgu-network-buttons a,
    .rgu-network-buttons button {
        margin-right: 0.5rem;
    }
}
