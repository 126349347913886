.tt-header-title {
    border-right-color: var(--blue-light);
}

.light-blue-dot {
    background-color: var(--blue-light);
}

.tt-blue-border-bottom {
    border-bottom-color: var(--blue-light);
}

.tt-ongoing-talks .slick-dots li button:before {
    color: var(--blue-light);
}

.tt-ongoing-talks .slick-dots li.slick-active button:before {
    color: var(--blue-light);
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1.5rem;
    margin-right: 0.5rem;
    height: 5px;
    background-color: var(--blue-light);
    border-radius: 5px;
}

.tt-ongoing-talks .slick-dots {
    bottom: -30px;
}

.past-tech-talks .prev-arrow img,
.past-tech-talks .next-arrow img {
    @apply md:w-6 lg:w-8;
}

.past-tech-talks .prev-arrow {
    left: -2.25rem;
}
.past-tech-talks .next-arrow {
    right: -2.25rem;
}

@media (max-width: 820px) {
    .past-tech-talks .prev-arrow {
        left: -2rem;
    }
    .past-tech-talks .next-arrow {
        right: -2rem;
    }
}
