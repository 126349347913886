.ef-blue-bg-gradient {
  background: rgb(239, 245, 254);
  background: linear-gradient(
    175deg,
    rgba(239, 245, 254, 1) 0%,
    rgba(169, 205, 248, 1) 54%,
    rgba(169, 205, 248, 1) 100%
  );
}

.ef-bg-green-gradient,
.ef-board-header div {
  background: rgb(148, 227, 223);
  background: linear-gradient(
    270deg,
    rgba(148, 227, 223, 1) 16%,
    rgba(97, 166, 235, 1) 100%
  );
}

.ef-stats .text-gradient,
.ef-board-header {
  background: rgb(181, 245, 140);
  background: linear-gradient(
    90deg,
    rgba(181, 245, 140, 1) 0%,
    rgba(68, 223, 222, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ef-about-head .gradient-underline {
  background: rgb(181, 245, 140);
  background: linear-gradient(
    90deg,
    rgba(181, 245, 140, 1) 0%,
    rgba(68, 223, 222, 1) 100%
  );
}

.ef-founders.bg-gradient {
  background: rgb(255, 162, 119);
  background: linear-gradient(
    90deg,
    rgba(255, 162, 119, 1) 0%,
    rgba(255, 235, 226, 1) 100%
  );
}

.ef-founder-title-underline {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 16%,
    rgba(200, 124, 93, 1) 100%
  );
  height: 4px;
  width: 12rem;
  margin-top: 0.5rem;
}

.ef-about p {
  margin-bottom: 0.5rem;
}
